<template>

    <div>

        <!--Service Table-->
        <b-card style="width: 100%; height: 100%;">

            <!-- Header -->
            <div slot="header" no-body>
                <span class="d-flex w-100 justify-content-between">
                    <span>
                        <h3 style="margin-bottom: 0"><b>Service Manager</b></h3>
                    </span>
                    <span style="display: inline-block; white-space: nowrap">
                        <fa-icon :icon="['fas', 'square-plus']" @click="openCreateModal"/>
                    </span>
                </span>
            </div>

            <v-client-table ref="dTable" style="width: 100%" :columns="columns"
                            :data="services" :options="tOptions" :theme="theme" class="dataTable">

                <div slot="h__edit">
                    <span style="float: right">Edit</span>
                </div>
                <div slot="edit" slot-scope="props" style="float: right">
                    <fa-icon :icon="['fas', 'pen-to-square']"
                       @click="openEditModal(props.row)"/>&nbsp;
                    <fa-icon :icon="['fas', 'trash']"
                       @click="openDeleteModal(props.row)"/>
                </div>
            </v-client-table>

        </b-card>

        <!--Create Service Modal-->
        <b-modal no-close-on-backdrop id="createService" ok-title="Submit" title="Create Custom Service" class="modal-dark">
            <div v-if="currentService !== null">
                <label>Service Name</label>
                <b-form-input v-model="currentService.serviceName" placeholder="Service Name" style="margin-bottom: 10px;"/>

                <label>Service Type</label>
                <b-form-input v-model="currentService.type" placeholder="Service Type" style="margin-bottom: 10px;"/>
            </div>


            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="$bvModal.hide('createService')">Cancel</b-button>
                <b-button size="sm" variant="danger" @click="createService">Submit</b-button>
            </template>
        </b-modal>

        <!--Edit Service Modal-->
        <b-modal no-close-on-backdrop id="editService" ok-title="Submit" title="Edit Service" class="modal-dark">
            <div v-if="currentService !== null">
                <label>Service Name</label>
                <b-form-input v-model="currentService.serviceName" placeholder="Service Name" style="margin-bottom: 10px;"/>

                <label>Service Type</label>
                <b-form-input v-model="currentService.type" placeholder="Service Type" style="margin-bottom: 10px;"/>
            </div>


            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="$bvModal.hide('editService')">Cancel</b-button>
                <b-button size="sm" variant="danger" @click="editService">Submit</b-button>
            </template>
        </b-modal>

        <!--Delete Service Modal-->
        <b-modal no-close-on-backdrop id="deleteService" ok-title="Submit" title="Edit Service" class="modal-dark">
            <p>If you would like to permanently remove this service please enter this phrase.</p>
            <br>
            <p style="margin-bottom: 0">Enter "I would like to delete this service":</p>
            <b-form-group description="case sensitive">
                <b-form-input @keyup.enter="enterDeleteService()" v-model="nameVerification" id="basicName1" type="text"></b-form-input>
            </b-form-group>

            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="$bvModal.hide('deleteService')">Cancel</b-button>
                <b-button :disabled="nameVerification !== 'I would like to delete this service'" size="sm" variant="danger" @click="deleteService()">Submit</b-button>
            </template>
        </b-modal>

    </div>

</template>

<script>
import iss from "@/services/iss";
import Vue from "vue";
import claimsStore from "@/store/claimsStore";
import {ClientTable} from "vue-tables-2";

Vue.use(ClientTable);

export default {

    name: "Services",

    data() {
        return {
            columns: ['serviceName', 'type', 'edit'],
            currentService: null,
            customClaims: [],
            nameVerification: "",
            services: [],
            theme: 'bootstrap4',
            tOptions: {
                sortable: ['serviceName', 'type'],
                filterable: ['serviceName', 'type'],
                orderBy: {column: 'serviceName', ascending: true},
                headings: {
                    serviceName: 'Name',
                    type: 'Type',
                    edit: 'Edit'
                },
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            }
        }
    },

    created() {
        this.getServices();
    },

    methods: {
        createService() {
            this.currentService.customClaims = claimsStore.getters.getSelectedClaims();
            iss.createService(this.currentService).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Service created",
                    style: 'success'
                });
                this.currentService = null;
                location.reload();
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Service could not be created",
                    style: 'error'
                });
            });

            this.$bvModal.hide("createService");
        },
        deleteService() {
            iss.deleteService(this.currentService._id).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Service deleted",
                    style: 'success'
                });
                this.currentService = null;
                location.reload();
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Service could not be deleted",
                    style: 'error'
                });
            });

            this.$bvModal.hide("deleteService");
        },
        editService() {
            this.currentService.customClaims = claimsStore.getters.getSelectedClaims();

            iss.updateService(this.currentService).then(() => {
                this.$mToast({
                    title: 'Success',
                    text: "Service updated",
                    style: 'success'
                });
                this.currentService = null;
                location.reload();
            }).catch(() => {
                this.$mToast({
                    title: 'Error',
                    text: "Service could not be updated",
                    style: 'error'
                });
            });

            this.$bvModal.hide("editService");
        },
        enterDeleteService() {
            if (this.nameVerification === 'I would like to delete this service') {
                this.deleteService();
            }
        },
        getServices() {
            iss.readServices().then(response => {
                this.services = response.data;
            });
        },
        openCreateModal() {
            this.currentService = {
                type: "",
                serviceName: "",
                customClaims: []
            };
            this.$bvModal.show("createService");
        },
        openDeleteModal(service) {
            this.currentService = service;
            this.$bvModal.show("deleteService");
        },
        openEditModal(service) {
            this.customClaims = service.customClaims;
            this.currentService = service;
            this.$bvModal.show("editService");
        }
    }

}
</script>